
import { defineComponent } from 'vue';
import { IonTabs, IonTabBar, IonTabButton, IonLabel, IonIcon, IonPage, IonRouterOutlet } from '@ionic/vue';
import { personAddOutline, radioOutline, restaurantOutline } from 'ionicons/icons';

export default defineComponent({
  name: 'KochenTabs',
  components: {
    IonLabel,
    IonTabBar,
    IonTabs,
    IonTabButton,
    IonIcon,
    IonPage,
    IonRouterOutlet,
  },
  setup() {
    // const loggedInComputed = computed( () => {
    //   return store.getters.getLoginState;
    // })

    return {
      personAddOutline,
      radioOutline,
      restaurantOutline,
      // loggedInComputed
    };
  },
});
